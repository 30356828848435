import A11yDialog from 'a11y-dialog';

class Team {

    constructor() {

        let popups = document.querySelectorAll('.dialog-container');

        Array.from(popups).forEach((popup) => {
            let dialog = new A11yDialog(popup);

            dialog.on('show', function () {
                const body = document.getElementsByTagName('body')[0];
                body.classList.add('dialog-open');
            });

            dialog.on('hide', function () {
                const body = document.getElementsByTagName('body')[0];
                body.classList.remove('dialog-open');
            });
        });
    }

}

export default Team;
