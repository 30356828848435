import Swiper, { Pagination, Keyboard, Autoplay, EffectFade } from "Parent/js/modules/swiper/swiper";

Swiper.use([Pagination, Keyboard, Autoplay, EffectFade]);

class Sliders {
	constructor() {

        this.home_slider_el = document.getElementById("homeSlider");
		this.home_slider = null;

		if (this.home_slider_el) {
			this.runHomeSlider();
		}
	}

	runHomeSlider() {
		this.home_slider = new Swiper(this.home_slider_el, {
            loop: true,
            slidesPerView: 1,
            autoHeight: false,
			effect: 'fade',
			speed: 2000,
			fadeEffect: {
			  crossFade: true,
				duration: 2000,
			},
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>';
				},
			},
		});
	}
}

export default Sliders;
