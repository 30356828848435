class Globals {

    constructor() {
        const menuToggle = document.getElementById('menuToggle');
        const mainMenu = document.getElementById('mainMenu');
        const menuBlock = document.getElementById('menuBlock');
        const body = document.querySelector('body');

        //Attach event listener to open.
        menuToggle.addEventListener("click", e => {
            mainMenu.classList.toggle('active');
            menuBlock.classList.toggle('active');
            body.classList.toggle('active');
        });
    }
}

export default Globals;
